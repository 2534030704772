<div class="banner" [ngClass]="{ 'banner-margin-top': addMarginTop, 'green-bg': viewState.isKsa }">
  <div class="banner__right">
    <img
      [src]="'assets/img/' + viewState.content.iconName + '.svg'"
      alt="flag of ksa"
      class="banner__right__icon"
    />
    <div class="banner__right__body">
      <p class="banner__right__body__title body1--bold">
        {{ trans(viewState.content.title) }}
      </p>
      <p
        class="banner__right__body__description body2--regular caption1--regular"
        [ngClass]="{ 'banner__right__body__description--ltr': isLTR }"
      >
        {{ trans(viewState.content.description) }}
      </p>
    </div>
  </div>
  <div
    class="banner__left"
    [ngClass]="{
      'banner__left--ltr': isLTR,
      'banner__left--rtl': isRTL
    }"
  >
    <ng-lottie
      [options]="options"
      [ngClass]="{
        'banner__left__animation--ltr': isLTR,
        'banner__left__animation--rtl': isRTL
      }"
    ></ng-lottie>
    <button
      class="banner__left__CTA body2--bold"
      (click)="presenter.onViewEvent({ type: 'ClickJoinNow' })"
    >
      {{ trans(viewState.content.buttonTitle) }}
      <img
        src="/assets/img/on-boarding/next-step-left-arrow.svg"
        alt="arrow"
        [ngClass]="{
            'banner__left__CTA__img banner__left__CTA__img--ltr': isLTR,
          }"
      />
    </button>
  </div>
</div>
